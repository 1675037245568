@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body {
    overflow-x: hidden;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: rgba(41, 41, 41, 0.5) !important;
    backdrop-filter: blur(10px);
    color: #00ffb3 !important;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important;
}

@media only screen and (max-width: 991px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}

* {
    scrollbar-width: auto;
    scrollbar-color: #33ffc2 #06131c;
}
*::-webkit-scrollbar {
    width: 3px;
}
*::-webkit-scrollbar-track {
    background: #06131c;
}
*::-webkit-scrollbar-thumb {
    background-color: #33ffc2;
}
